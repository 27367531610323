import React, { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';

import {
  FEATURE_FLAGS_FOR_SELLER,
  POSITIONS,
  ROLES,
} from '@savgroup-front-common/constants';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import {
  useHasRole,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import { ROUTES } from 'control/constants/routing';
import SearchWithDimmer from 'control/view/molecules/SearchWithDimmer/SearchWithDimmer';
import { ResultSearchType } from 'control/view/molecules/SearchWithDimmer/SearchWithDimmer.types';

import { useGetFeatureFlagsQuery } from '../../../hooks';
import { SearchInputVariant } from '../../atoms/SearchInput/SearchInput.types';

import CreateButton from './CreateButton';
import { useHeaderNewBo } from './Header.hooks';
import {
  $AppHeaderContainer,
  $HeaderLogo,
  $HeaderPortal,
  $HeaderSearchBar,
  $NavigationMenuWrapper,
  $SellerSelectPortal,
} from './Header.styles';
import Logo from './Logo';
import Menu from './Menu';
import messages from './messages';
import Profile from './Profile';
import Search from './Search';
import { SwitchNewBo } from './SwitchNewBo/SwitchNewBo';
import { UpgradePlanButton } from './UpgradePlanButton/UpgradePlanButton';

interface HeaderProps {
  onLogoClick: () => void;
  isMenuOpen: boolean;
}

const Header: FC<React.PropsWithChildren<HeaderProps>> = ({
  onLogoClick,
  isMenuOpen,
}) => {
  const isConfigurationConsolePage = !!useMatch(ROUTES.CONFIGURATION_CONSOLE);

  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const { isNewUiEnabled } = useNewUiContext();
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);
  const isSuperAdmin = useIsSuperAdmin();

  const isFromScratch = useMatch(ROUTES.FROM_SCRATCH);

  const {
    isLoading,
    searchResult,
    query,
    handleChangeSearchQuery,
    handleShowMore,
  } = useHeaderNewBo();

  if (isNewUiEnabled) {
    return (
      <$AppHeaderContainer $isNewUiEnabled>
        <$HeaderLogo>
          <Link to={ROUTES.HOME}>
            <ReversioIcon color="#fff" />
          </Link>
        </$HeaderLogo>
        {!isFromScratch && (
          <$NavigationMenuWrapper>
            <Menu />
          </$NavigationMenuWrapper>
        )}
        {!isConfigurationConsolePage && !isFromScratch && (
          <>
            {canSwitchToNewBo && !isConfigurationConsolePage && <SwitchNewBo />}
            {(isDemoAdmin || isSuperAdmin) && <UpgradePlanButton />}

            <CreateButton />

            <$HeaderSearchBar>
              <SearchWithDimmer
                isLoading={isLoading}
                query={query}
                placeholder={messages.searchPlaceholder}
                results={
                  (searchResult as SearchResultList<ResultSearchType>[]) || []
                }
                onSearch={handleChangeSearchQuery}
                onShowMore={handleShowMore}
                minWidthResult={488}
                position={POSITIONS.RIGHT}
                topResult={48}
                withoutMarginBottom
                isHightContrast
                variant={SearchInputVariant.GLOBAL_SEARCH_NEW_UI}
              />
            </$HeaderSearchBar>
          </>
        )}
        {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
        {isConfigurationConsolePage && (
          <$SellerSelectPortal id="headerSellerSelectPortalId" />
        )}
        <Profile />
      </$AppHeaderContainer>
    );
  }

  return (
    <$AppHeaderContainer $isNewUiEnabled={false}>
      <Logo onClick={onLogoClick} isMenuOpen={isMenuOpen} />
      {!isConfigurationConsolePage && (
        <>
          <CreateButton />

          <Search />
        </>
      )}
      {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
      {isConfigurationConsolePage && (
        <$SellerSelectPortal id="headerSellerSelectPortalId" />
      )}
      {canSwitchToNewBo && !isConfigurationConsolePage && <SwitchNewBo />}
      <Profile />
    </$AppHeaderContainer>
  );
};

export default Header;
